<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container id="iiotComposeContainer" fill-height fluid class="pl-0 pt-0">
    <v-row>
      <v-col>
        <v-row>
          <v-col cols="12" lg="11">
            <div v-if="!isWorkloadEmpty" class="title">
              <h1>
                {{
                  isNew
                    ? $t('workloadVersion.newWl')
                    : isUpdate
                      ? $t('workloadVersion.editWl')
                      : isClone
                        ? $t('workloadVersion.cloneWl')
                        : $t('workloadVersion.title')
                }}
              </h1>
              <v-divider />
            </div>
          </v-col>
        </v-row>
        <v-form
          v-if="!isWorkloadEmpty && isVersionReady"
          id="iiotComposeForm"
          ref="form"
          data-cy="iiotComposeForm"
          @submit.prevent="submitForm"
        >
          <v-col class="pl-0 mb-10">
            <v-row>
              <v-col>
                <v-tabs
                  v-model="tab"
                  class="tab-text-transform"
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows
                >
                  <v-tab
                    id="iiotComposeBasicTab"
                    :key="basic"
                    data-cy="iiotComposeBasicTab"
                    autofocus
                    @click="tabChanged(0)"
                  >
                    <h4 v-if="isBasicComposeFormValid">
                      {{ $t('workloadVersion.basic') }}
                    </h4>
                    <h4 v-if="!isBasicComposeFormValid" class="required">
                      {{ $t('workloadVersion.requiredBasic') }}
                    </h4>
                  </v-tab>
                  <v-tooltip v-if="!fileContent || !getIsComposeFileValid" bottom max-width="70%">
                    <template #activator="{ on }">
                      <div class="d-flex align-center" v-on="on">
                        <v-tab
                          id="iiotComposeRegistriesAndServicesTab"
                          :key="registriesAndServices"
                          data-cy="iiotComposeRegistriesAndServicesTab"
                          autofocus
                          disabled
                        >
                          <h4
                            v-if="isRegistriesAndServicesComposeFormValid || !hasRegistriesAndServicesTabBeenSelected"
                          >
                            {{ $t('workloadVersion.registriesAndServices') }}
                          </h4>
                          <h4 v-else class="required">
                            {{ $t('workloadVersion.requiredRegistriesAndServices') }}
                          </h4>
                        </v-tab>
                      </div>
                    </template>
                    <span> {{ $t('workloadVersion.dockerComposeInfoMessage') }}</span>
                  </v-tooltip>
                  <v-tab
                    v-else
                    id="iiotComposeRegistriesAndServicesTab"
                    :key="registriesAndServices"
                    data-cy="iiotComposeRegistriesAndServicesTab"
                    autofocus
                    @click="tabChanged(1)"
                  >
                    <h4
                      v-if="isRegistriesAndServicesComposeFormValid || !hasRegistriesAndServicesTabBeenSelected"
                      :class="{ required: isNotCancelled }"
                    >
                      {{ $t('workloadVersion.registriesAndServices') }}
                    </h4>
                    <h4 v-else class="required">
                      {{ $t('workloadVersion.requiredRegistriesAndServices') }}
                    </h4>
                    <v-tooltip v-if="showWarning" bottom>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-img
                            id="iiotComposeRegistryWarningIcon"
                            src="/img/warning.svg"
                            max-height="17px"
                            max-width="17px"
                            class="ml-2 mt-1"
                          />
                        </div>
                      </template>
                      <span>
                        {{ $t('workloadVersion.passwordIsRequiredForRegistryCompose') }}
                      </span>
                    </v-tooltip>
                  </v-tab>
                  <v-tooltip v-if="!fileContent || !getIsComposeFileValid" bottom max-width="70%">
                    <template #activator="{ on }">
                      <div class="d-flex align-center" v-on="on">
                        <v-tab
                          id="iiotComposeRemoteConnectionTab"
                          :key="remoteConnections"
                          data-cy="iiotComposeRemoteConnectionTab"
                          disabled
                        >
                          <h4>{{ $t('workloadVersion.remoteConnections') }}</h4>
                        </v-tab>
                      </div>
                    </template>
                    <span> {{ $t('workloadVersion.dockerComposeInfoMessage') }}</span>
                  </v-tooltip>
                  <v-tab
                    v-else
                    id="iiotComposeRemoteConnectionTab"
                    :key="remoteConnections"
                    data-cy="iiotComposeRemoteConnectionTab"
                    @click="tabChanged(2)"
                  >
                    <h4>{{ $t('workloadVersion.remoteConnections') }}</h4>
                    <v-tooltip v-if="showRcWarning || showRcServicesWarning" bottom>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-img
                            id="iiotDockerRemoteConnectionsWarningIcon"
                            src="/img/warning.svg"
                            max-height="17px"
                            max-width="17px"
                            class="ml-2 mt-1"
                          />
                        </div>
                      </template>
                      <span v-if="showRcWarning">
                        {{ $t('remoteConnection.passwordIsRequiredForVncTooltip') }}
                      </span>
                      <span v-else>
                        {{ $t('remoteConnection.servicesDoNotMatchComposeFile') }}
                      </span>
                    </v-tooltip>
                  </v-tab>
                </v-tabs>
                <v-divider />
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item :key="basic" eager>
                <basic-compose
                  ref="basicCompose"
                  type="docker-compose"
                  :version="version"
                  :is-released="isReleased"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="registriesAndServices" eager>
                <registries-and-services-compose
                  ref="registriesAndServicesCompose"
                  type="docker-compose"
                  :version="version"
                  :compose-file-changed="hasComposeFileChanged"
                  :is-update="isUpdate"
                  :is-released="isReleased"
                  :show-warning="showWarning"
                  @submit-event="submitEventHandler"
                />
              </v-tab-item>
              <v-tab-item :key="remoteConnections" eager>
                <v-col cols="12" lg="4">
                  <remote-connection-list
                    id="iiotComposeList"
                    data-cy="iiotComposeList"
                    :model="version"
                    version-type="docker-compose"
                    :initial-number-of-connections="initialNoOfConnections"
                    :show-warning="showRcWarning"
                    :list-of-services="version.services"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col class="ml-auto">
            <v-row class="d-flex align-center fixed-buttons">
              <nerve-button
                id="iiotComposeCancelButton"
                data-cy="iiotComposeCancelButton"
                :text="$t('baseForm.cancelBtn')"
                type-of-btn="cancel"
                size="normal"
                class="mr-5 ml-0"
                @click-event="cancel()"
              />
              <nerve-button
                v-if="
                  (!isUpdate && !canAccess('UI_WORKLOAD:VERSION_EDIT')) ||
                  (isUpdate && canAccess('UI_WORKLOAD:VERSION_EDIT')) ||
                  (!isUpdate && canAccess('UI_WORKLOAD:VERSION_CREATE'))
                "
                id="iiotComposeSaveButton"
                data-cy="iiotComposeSaveButton"
                :disabled="!isSaveEnabled || !isValid"
                :text="$t('baseForm.saveBtn')"
                type-of-btn="action"
                size="normal"
                type="submit"
              />
            </v-row>
          </v-col>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import YAML from 'json-to-pretty-yaml';
import { NerveButton } from 'nerve-ui-components';
import RemoteConnectionList from '@/components/remote-connection/RemoteConnectionList.vue';
import Logger from '@/utils/logger';
import BasicCompose from '@/components/workloads/workloadVersionComponents/BasicCompose.vue';
import registriesAndServicesCompose from '@/components/workloads/workloadVersionComponents/RegistriesAndServicesCompose.vue';
import shared from '@/helpers/shared';

export default {
  components: {
    RemoteConnectionList,
    BasicCompose,
    NerveButton,
    registriesAndServicesCompose,
  },
  data: () => ({
    isUpdate: false,
    tab: null,
    basic: 0,
    registriesAndServices: 1,
    remoteConnections: 2,
    workloadId: '',
    isBasicComposeFormValid: true,
    isRegistriesAndServicesComposeFormValid: true,
    availableTabs: [0, 1, 2],
    createAvailableTabs: [0],
    versionId: '',
    isWorkloadEmpty: false,
    onCreated: false,
    isReleased: false,
    isValid: false,
    isVersionReady: false,
    hasComposeFileChanged: false,
    composeFileId: '',
    dockerFileId: '',
    noOfVersions: 0,
    selectors: [],
    wlId: '',
    dockerImagePath: '',
    isNew: false,
    isClone: false,
    hasRegistriesAndServicesTabBeenSelected: false,
    composeAlreadySent: false,
    initialNoOfConnections: 0,
    oldUsernames: [],
  }),
  computed: {
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
    workload() {
      return this.$store.getters['workloads/getComposeWorkload'];
    },
    getNameAndDesc() {
      return this.$store.getters['workloads/getNameAndDesc'];
    },
    isSaveEnabled() {
      return this.$store.getters['workloads/isSaveEnabled'];
    },
    getFormChangedFlag() {
      return this.$store.getters['workloads/getFormChangedFlag'];
    },
    getVersionId() {
      return this.$store.getters['workloads/getVersionId'];
    },
    getOldNameAndDesc() {
      return this.$store.getters['workloads/getOldNameAndDesc'];
    },
    isNotCancelled() {
      return this.version.errorMessage && this.version.errorMessage !== 'nerve_workload_files_cancelled';
    },
    getIsComposeFileValid() {
      return this.$store.getters['workloads/getIsComposeFileValid'];
    },
    fileContent() {
      const file = this.$store.getters['workloads/fileContent'];
      if (typeof file === 'object') {
        return file ? YAML.stringify(file) : '';
      }
      return file;
    },
    getChangedDockerImages() {
      return this.$store.getters['workloads/getChangedDockerImages'];
    },
    originalFiles() {
      return this.$store.getters['workloads/getOriginalFiles'];
    },
    selectedDeployedWorkloadVersionTimestamp() {
      return this.$store.getters['workloads/getSelectedDeployedWorkloadVersionTimestamp'];
    },
    getIsTimestampFromActiveRC() {
      return this.$store.getters['workloads/getIsTimestampFromActiveRC'];
    },
    showWarning() {
      if (this.oldUsernames.find((username) => username !== '')) {
        const servicesWithFilesWithUsernameButNoPassword = this.version?.services?.find(
          (service) => service.file?.origin === 'docker-repo' && service.file?.username && !service.file.password,
        );
        return (
          this.isNew &&
          !!this.workload?.versions &&
          !!this.workload.versions[0]?._id &&
          !!servicesWithFilesWithUsernameButNoPassword
        );
      }
      return false;
    },
    showRcWarning() {
      return (
        !this.isUpdate &&
        !!this.version.remoteConnections?.find(
          (rc) => (rc.connection?.toLowerCase() === 'vnc' && !rc.password) || (rc.username && !rc.password),
        )
      );
    },
    showRcServicesWarning() {
      let showWarning = false;
      const listOfServices = [];
      this.version.services.forEach((service) => listOfServices.push(service.serviceName));
      this.version.remoteConnections.forEach((rc) => {
        if (!listOfServices.includes(rc.serviceName)) {
          showWarning = true;
        }
      });
      return showWarning;
    },
  },
  watch: {
    isSaveEnabled(newValue) {
      if (newValue) {
        this.$nextTick(async () => {
          this.isValid = await this.isComposeFormValid();
        });
      }
    },
    getFormChangedFlag() {
      this.formChanged();
      this.$nextTick(() => {
        this.$refs.basicCompose?.$refs.versionUploadCompose?.$refs.uploadFileComposeForm?.$el.addEventListener(
          'input',
          () => {
            this.formChanged();
            this.hasComposeFileChanged = true;
            this.composeAlreadySent = false;
          },
        );
      });
    },
  },
  async created() {
    try {
      const route = window.location.pathname.split('/');
      this.versionId = route[route.length - 2];
      if (this.versionId === 'clone') {
        this.isClone = true;
      }
      this.workloadId = route[route.length - 4];
      if (this.versionId === 'new') {
        await this.$store.dispatch('workloads/set_file_content', '');
        this.isNew = true;
      }
      this.isUpdate = !this.isNew && !this.isClone;
      const tab = Number(this.$route.query.tab);
      const tabs = this.isUpdate ? this.availableTabs : this.createAvailableTabs;
      if (tabs.includes(tab)) {
        this.tabChanged(Number(this.$route.query.tab));
      } else {
        this.tabChanged(0);
      }
      this.version.released = false;
      await this.$store.dispatch('workloads/get_compose_workload_by_id', this.workloadId);
      this.workload.type = 'docker-compose';
      if (this.versionId !== 'clone') {
        await this.$store.dispatch('workloads/get_compose_version_by_id', {
          id: this.versionId,
          isUpdate: this.isUpdate,
        });
      }
      this.version.registries.forEach((registry) => this.oldUsernames.push(registry.username));
      const timeOfUpdate = this.version.updatedAt && new Date(this.version.updatedAt).getTime();
      if (
        this.selectedDeployedWorkloadVersionTimestamp &&
        timeOfUpdate &&
        this.selectedDeployedWorkloadVersionTimestamp < timeOfUpdate
      ) {
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.getIsTimestampFromActiveRC
            ? 'workloadVersion.versionHasChangedSinceRc'
            : 'workloadVersion.versionHasChanged',
          color: 'primary',
          showClose: true,
        });
      }
      if (this.versionId === 'clone' && !this.version.id) {
        this.$router.push({
          name: 'Add edit workload',
          params: { id: this.workloadId, type: 'docker-compose' },
        });
      }

      if (!this.isUpdate) {
        this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
      }
      this.version.services.forEach((service) => {
        if (service.file.status !== 'available') {
          this.$store.dispatch('workloads/set_save_enabled');
        }
      });
      this.composeFileId = this.version.composeFile && this.version.composeFile._id;
      this.noOfVersions =
        this.workload && this.workload.versions && this.workload.versions[0] && this.workload.versions[0]._id
          ? this.workload.versions.length
          : 0;

      this.isVersionReady = true;
      if (this.versionId === 'new') {
        this.version.released = false;
      }
      this.isReleased = this.version.released;

      // Add listeners for changes on all forms
      this.$nextTick(() => {
        this.addListeners();
      });
      await this.$store.dispatch('remote-connection/fetch', this.version);
      this.initialNoOfConnections = this.$store.getters['remote-connection/list'].length;
      if (this.getNameAndDesc.name === '' && this.workload.name === '') {
        this.cancel();
      }
      if (this.getNameAndDesc.name === '' && this.workload.name !== '') {
        return;
      }
      if (this.getNameAndDesc.name !== '' && this.workload.name === '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
        return;
      }
      if (this.workload.name !== this.getNameAndDesc.name && this.workload.name !== '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
      }
      if (this.version.errorMessage) {
        this.$store.dispatch('workloads/set_save_enabled');
        this.isValid = true;
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  async updated() {
    await this.$store.dispatch('remote-connection/fetch', this.version);
  },
  beforeDestroy() {
    this.$store.dispatch('workloads/disable_save_button');
    this.$store.dispatch('workloads/clear_original_files');
  },

  methods: {
    async submitForm() {
      if (!this.isComposeFormValid()) {
        return;
      }
      if (this.version.released) {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.markAsReleasedMessageTitle',
          subTitle: 'workloadVersion.markAsReleasedMessageText',
          callback: async () => {
            this.createVersion();
          },
        });
      } else {
        this.createVersion();
      }
    },

    async createVersion() {
      // If workload doesn't already exist it should be created first before creating new version
      if (this.workloadId === 'new' && !this.wlId) {
        const { name, description } = this.getNameAndDesc;
        try {
          // Only there 4 properties are sent because of the api validation
          this.wlId = await this.$store.dispatch('workloads/create_compose_workload', {
            type: this.workload.type,
            name,
            description,
            disabled: false,
          });
          this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
          // if creation of the workload fails user shouldn't be rerouted to version page
          if (!this.wlId) {
            this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
            return;
          }
        } catch {
          this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
          return;
        }
      }
      const isAddVersionOnExistingWl = !this.isUpdate && this.version._id !== '';
      if (isAddVersionOnExistingWl) {
        delete this.version._id;
      }
      // If connection type is 'SCREEN' remove headerType and location
      this.version?.remoteConnections?.forEach((connection) => {
        connection.port = parseInt(connection.port, 10);
        connection.localPort = parseInt(connection.localPort, 10);
        delete connection.headerType;
        if (connection.type === 'SCREEN') {
          delete connection.location;
        }
      });
      this.selectors = [];
      // Transform selectors from list of objects to list of selector ids
      // eslint-disable-next-line array-callback-return
      this.version.selectors.map((el) => {
        this.selectors.push(el._id);
      });
      this.workload.versions = [this.version];
      this.workload.type = 'docker-compose';
      try {
        const wlData = {
          ...(this.getOldNameAndDesc.name !== this.getNameAndDesc.name && {
            name: this.getNameAndDesc.name,
          }),
          ...(this.getOldNameAndDesc.description !== this.getNameAndDesc.description && {
            description: this.getNameAndDesc.description,
          }),
        };
        // If workload name or description are changed but not saved save them before saving version
        if (this.getOldNameAndDesc.name && (wlData.name || wlData.description)) {
          try {
            await this.$store.dispatch('workloads/update_compose_workload', {
              id: this.workloadId,
              data: wlData,
            });
            this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
          } catch {
            this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
            return;
          }
        }
        // eslint-disable-next-line max-len
        let dockerConfigurationStorage = this.version.workloadSpecificProperties.dockerConfigurationStorage.filter(
          (storage) => storage.volumeName,
        );
        dockerConfigurationStorage = dockerConfigurationStorage.filter(
          (storage) => storage.volumeName !== this.$t('workloadVersion.emptyState'),
        );
        // Create object to be sent in create/edit version request
        const data = {
          name: this.version.name,
          released: this.version.released,
          selectors: this.selectors,
          remoteConnections: this.version.remoteConnections,
          workloadSpecificProperties: { dockerConfigurationStorage },
        };
        // Create or update the version
        if (!this.version._id && (this.versionId === 'new' || this.versionId === 'clone')) {
          const newVersion = await this.$store.dispatch('workloads/create_compose_workload_version', {
            wlId: this.workloadId === 'new' ? this.wlId : this.workloadId,
            data,
          });
          this.version.remoteConnections = newVersion.remoteConnections;
          this.versionId = newVersion._id;
          // Define and send all files
          await this.sendFiles();
        } else {
          const updatedVersion = await this.$store.dispatch('workloads/update_compose_workload_version', {
            wlId: this.workloadId === 'new' ? this.wlId : this.workloadId,
            verId: this.versionId,
            data,
          });
          this.version.remoteConnections = updatedVersion.remoteConnections;
          await this.sendFiles();
        }
        // Clear files from store
        this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
        this.$store.dispatch('workloads/save_uploaded_compose_file', {});

        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        return;
      }
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: this.workloadId === 'new' ? 'workloadDetail.successfullyCreate' : 'workloadDetail.successfullyUpdate',
        color: 'green',
        showClose: true,
      });
      if (this.workloadId === 'new') {
        this.$router.push({ name: 'Workloads', query: this.$store.getters['labels/getQuery'] });
        return;
      }
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.workloadId, type: 'docker-compose' },
      });
    },

    async isComposeFormValid() {
      this.isBasicComposeFormValid =
        (await this.$refs.basicCompose.$refs.basicComposeForm.validate()) &&
        (await this.$refs.basicCompose?.$refs.versionUploadCompose?.$refs.uploadFileComposeForm.validate());
      // eslint-disable-next-line max-len
      this.isRegistriesAndServicesComposeFormValid =
        await this.$refs.registriesAndServicesCompose.$refs.registriesAndServicesComposeForm.validate();
      return this.isBasicComposeFormValid && this.isRegistriesAndServicesComposeFormValid && !this.showWarning;
    },
    async tabChanged(tab) {
      if (tab === 1) {
        this.hasRegistriesAndServicesTabBeenSelected = true;
      }
      this.tab = tab;
      await this.$router
        .push({
          name: 'Add edit workload version',
          query: {
            tab,
          },
        })
        .catch(() => {});
    },
    cancel() {
      this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
      this.$store.dispatch('workloads/save_uploaded_compose_file', {});
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.wlId || this.workloadId, type: 'docker-compose' },
      });
    },
    submitEventHandler() {
      this.submitForm();
    },
    async formChanged() {
      this.isValid = await this.isComposeFormValid();
      if (this.isValid) {
        if (this.version.services.find((service) => service.file.status !== 'available')) {
          this.$store.dispatch('workloads/set_save_enabled');
          return;
        }
        this.$store.dispatch('workloads/set_save_enabled', this.version);
        return;
      }
      this.$store.dispatch('workloads/disable_save_button');
    },
    addListeners() {
      this.$refs.basicCompose?.$refs.basicComposeForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.registriesAndServicesCompose?.$refs.registriesAndServicesComposeForm?.$el.addEventListener(
        'input',
        () => {
          this.formChanged();
        },
      );
      this.$refs.basicCompose?.$refs.versionUploadCompose?.$refs.uploadFileComposeForm?.$el.addEventListener(
        'input',
        () => {
          this.formChanged();
          this.hasComposeFileChanged = true;
          this.composeAlreadySent = false;
        },
      );
    },

    async sendFiles() {
      await this.prepareAndSendComposeFile();
      await this.defineAllFiles();
      await this.patchFilesIfNecessary();
    },

    // Function for creating appropriate docker-compose form and sending file
    async prepareAndSendComposeFile() {
      // If file is already sent or if version is being edited and compose file hasn't changed
      if (this.composeAlreadySent || (this.isUpdate && !this.hasComposeFileChanged)) {
        return;
      }
      const form = new FormData();
      // If compose doesn't exist send uploaded file as POST request
      if (!this.composeFileId) {
        await this.createFileForm({
          form,
          type: 'compose',
          origin: 'upload',
          source: 'file',
          file: this.$store.getters['workloads/getUploadedComposeFile'],
        });
        return;
      }
      if (this.isClone || (this.noOfVersions > 0 && !this.isUpdate)) {
        // If the version is clone or its not first version and new compose has been uploaded send uploaded file as POST request
        await this.createFileForm({
          form,
          type: 'compose',
          origin: this.hasComposeFileChanged ? 'upload' : 'clone',
          source: this.hasComposeFileChanged ? 'file' : this.composeFileId,
          ...(this.hasComposeFileChanged && {
            file: this.$store.getters['workloads/getUploadedComposeFile'],
          }),
        });
        return;
      }
      // If user is editing version and new compose is uploaded send it as PATCH request
      if (this.hasComposeFileChanged) {
        await this.createFileForm({
          form,
          type: 'compose',
          origin: 'upload',
          source: 'file',
          file: this.$store.getters['workloads/getUploadedComposeFile'],
          fileId: this.composeFileId,
        });
      }
    },

    async defineAllFiles() {
      if (this.isUpdate && !this.hasComposeFileChanged) {
        return;
      }
      if ((this.isUpdate && this.hasComposeFileChanged) || this.isClone || this.noOfVersions > 0) {
        await this.defineAllFilesEditCloneNotFirstVersion();
        return;
      }
      await this.defineAllFilesFirstVersion();
    },

    async defineAllFilesFirstVersion() {
      const all_files = [];
      this.version.services.forEach((service) => {
        let file = {};
        if (service.file && service.file.source && service.file.source.includes('file')) {
          file = {
            type: 'docker-image',
            origin: 'upload',
            source: service.dockerFilePath,
          };
        } else {
          file = {
            type: 'docker-image',
            origin: 'docker-repo',
            source: service.dockerFilePath,
            ...(service.file && service.file.username && { username: service.file.username }),
            ...(service.file && service.file.password && { password: service.file.password }),
          };
        }
        if (
          all_files.length === 0 ||
          !all_files.find((f) => shared.compareTwoDockerImageNamesAreEqual(f.source, file.source))
        ) {
          all_files.push(file);
        }
      });
      const files = await this.$store.dispatch('workloads/define_all_files', {
        wlId: this.workloadId === 'new' ? this.wlId : this.workloadId,
        verId: this.versionId,
        data: { files: all_files },
      });
      this.version.services.forEach((service) => {
        const matchedFile = files.find((f) =>
          shared.compareTwoDockerImageNamesAreEqual(service.dockerFilePath, f.source),
        );
        service.file._id = matchedFile._id;
        this.$store.dispatch('workloads/save_changed_docker_images_specific_property', {
          imageName: service.dockerFilePath,
          property: '_id',
          value: matchedFile._id,
        });
      });
    },
    async defineAllFilesEditCloneNotFirstVersion() {
      const all_files = [];
      this.version.services.forEach((service) => {
        const key =
          Object.keys(this.getChangedDockerImages)?.find((image) =>
            shared.compareTwoDockerImageNamesAreEqual(image, service.dockerFilePath),
          ) || '';
        const uploadedFile = key ? this.getChangedDockerImages[key] : {};
        let file = {};
        if (service.file.origin !== 'upload') {
          const oldFile = this.originalFiles.find((originalFile) =>
            shared.compareTwoDockerImageNamesAreEqual(originalFile.file.source, service.dockerFilePath),
          );
          if (this.isClone && !this.hasComposeFileChanged && oldFile.file.origin !== 'upload') {
            file = {
              type: 'docker-image',
              origin: 'clone',
              source: service.file._id,
            };
          } else {
            file = {
              type: 'docker-image',
              origin: 'docker-repo',
              source: service.dockerFilePath,
              ...(service.file && service.file.username && { username: service.file.username }),
              ...(service.file && service.file.password && { password: service.file.password }),
            };
          }
        } else if (uploadedFile && uploadedFile.file) {
          file = {
            type: 'docker-image',
            origin: 'upload',
            source: service.dockerFilePath,
          };
        } else {
          file = {
            type: 'docker-image',
            origin: 'clone',
            source: service.file._id,
          };
        }
        if (
          all_files.length === 0 ||
          !all_files.find((f) => shared.compareTwoDockerImageNamesAreEqual(f.source, file.source))
        ) {
          all_files.push(file);
        }
      });
      const files = await this.$store.dispatch('workloads/define_all_files', {
        wlId: this.workloadId === 'new' ? this.wlId : this.workloadId,
        verId: this.versionId,
        data: { files: all_files },
      });
      this.version.services.forEach((service) => {
        const matchedFile = files.find((f) =>
          shared.compareTwoDockerImageNamesAreEqual(service.dockerFilePath, f.source),
        );
        service.file._id = matchedFile._id;
        this.$store.dispatch('workloads/save_changed_docker_images_specific_property', {
          imageName: service.dockerFilePath,
          property: '_id',
          value: matchedFile._id,
        });
      });
    },
    async patchFilesIfNecessary() {
      if (Object.keys(this.getChangedDockerImages).length === 0) {
        return;
      }
      await Promise.all(
        Object.keys(this.getChangedDockerImages).map(async (key) => {
          const changedOrUploadedFile = this.getChangedDockerImages[key];
          if (!changedOrUploadedFile._id) {
            return;
          }
          const form = new FormData();
          await this.createFileForm({
            form,
            type: 'docker-image',
            origin: changedOrUploadedFile.origin,
            source: changedOrUploadedFile.source,
            fileId: changedOrUploadedFile._id,
            ...(changedOrUploadedFile.origin === 'docker-repo' && {
              username: changedOrUploadedFile.username,
            }),
            ...(changedOrUploadedFile.origin === 'docker-repo' && {
              password: changedOrUploadedFile.password,
            }),
            ...(changedOrUploadedFile.file && { file: changedOrUploadedFile.file }),
          });
        }),
      );
    },
    async createFileForm({ form, type, origin, source, username, password, file, fileId }) {
      form.append('type', type);
      form.append('origin', origin);
      form.append('source', source);
      if (username) {
        form.append('username', username);
      }
      if (password) {
        form.append('password', password);
      }
      if (file) {
        form.append('file', file);
      }
      const returnedFile = await this.$store.dispatch(fileId ? 'workloads/change_file' : 'workloads/upload_file', {
        wlId: this.workloadId === 'new' ? this.wlId : this.workloadId,
        verId: this.versionId,
        ...(fileId && { fileId }),
        data: form,
        origin,
      });

      if (returnedFile.type === 'compose' && returnedFile.status === 'available') {
        this.composeAlreadySent = true;
        this.composeFileId = returnedFile._id;
      }
    },
  },
};
</script>

<style scoped>
.fixed-buttons {
  position: fixed !important;
  bottom: 0px;
  background: white;
  z-index: 10;
  padding-bottom: 30px;
  padding-right: 10px;
}
.required {
  color: var(--v-error-base) !important;
}
</style>
