<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-container
    v-if="version && type === 'docker-compose'"
    id="iiotRegistriesAndServicesComposeContainer"
    fluid
    class="mt-4"
  >
    <v-form
      id="iiotRegistriesAndServicesComposeForm"
      ref="registriesAndServicesComposeForm"
      data-cy="iiotRegistriesAndServicesComposeForm"
    >
      <v-row>
        <v-col
          v-resize="onResize"
          lg="3"
          cols="12"
          class="registriesDiv registries-scroll"
          :class="{ 'pr-12': isMarginVisible }"
        >
          <span>{{ $t('workloadVersion.titleRegistry') }}</span>
          <v-row v-if="showWarning">
            <v-col cols="12" class="mt-5">
              <span class="font-size-16 warningMessage">
                {{ $t('workloadVersion.passwordIsRequiredForRegistryCompose') }}
              </span>
            </v-col>
          </v-row>
          <div v-for="(registry, index) in version.registries" :key="registry.registryName" class="pt-5">
            <span class="registryName">{{ registry.registryName }}</span>
            <v-text-field
              :id="`iiotFromRegistryUsername_${index}`"
              v-model="registry.username"
              :label="$t('workloadVersion.usernameLabel')"
              :rules="[rules.dockerUsername, rules.dockerComposeUsernameMinLength]"
              :maxlength="DOCKER_USERNAME_LENGTH.MAX_LENGTH"
              :minlength="DOCKER_USERNAME_LENGTH.MIN_LENGTH"
              :disabled="isReleased"
              class="credentialsTextField"
              @input="setNewCredentialsToFile(registry)"
            />
            <v-text-field
              :id="`iiotFromRegistryPassword_${index}`"
              v-model="registry.password"
              :append-icon="getIcon(registry)"
              :rules="[rules.dockerPassword, rules.dockerComposePasswordMinLength]"
              :label="$t('workloadVersion.passwordLabel')"
              :maxlength="DOCKER_PASSWORD.MAX_LENGTH"
              :minlength="DOCKER_PASSWORD.MIN_LENGTH"
              :disabled="isReleased"
              :type="registry.textFieldType"
              class="credentialsTextField pt-1"
              @keyup="passwordChanged(registry)"
              @click:append="togglePassword(registry)"
              @input="setNewCredentialsToFile(registry)"
            />
          </div>
        </v-col>
        <div v-if="isMarginVisible">
          <v-divider vertical />
        </div>
        <v-col lg="9" cols="12" :class="{ 'pl-12': isMarginVisible }">
          <span>{{ $t('workloadVersion.titleServices') }}</span>
          <nerve-simple-table
            id="RegistriesAndServicesCompose"
            ref="servicesTable"
            :headers="tableHeaders"
            :items="version.services"
            :empty-state="$t('workloadVersion.services.empty')"
            :row-classes="getRowClass"
            class="servicesTable"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { NerveSimpleTable } from 'nerve-ui-components';
import { isEqual } from 'lodash';
import Logger from '@/utils/logger';
import { VALIDATION_REGEX, DOCKER_PASSWORD, DOCKER_USERNAME_LENGTH } from '@/constants';
import shared from '@/helpers/shared';

export default {
  components: {
    NerveSimpleTable,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    version: {
      type: Object,
      default: () => {},
    },
    isReleased: {
      type: Boolean,
    },
    composeFileChanged: {
      type: Boolean,
    },
    isUpdate: {
      type: Boolean,
    },
    showWarning: {
      type: Boolean,
    },
  },
  data: () => ({
    VALIDATION_REGEX,
    DOCKER_PASSWORD,
    DOCKER_USERNAME_LENGTH,
    isMarginVisible: false,
  }),
  computed: {
    rules() {
      return {
        dockerUsername: (value) =>
          VALIDATION_REGEX.DOCKER_USERNAME.test(value) || this.$t('workloadVersion.dockerUsernamePattern'),
        dockerPassword: (value) =>
          VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('workloadVersion.dockerPasswordPattern'),
        dockerComposePasswordMinLength: (value) =>
          value
            ? value.length === 0 ||
              value.length >= DOCKER_PASSWORD.MIN_LENGTH ||
              this.$t('workloadVersion.dockerPasswordLength')
            : true,
        dockerComposeUsernameMinLength: (value) =>
          value
            ? value.length === 0 ||
              value.length >= DOCKER_USERNAME_LENGTH.MIN_LENGTH ||
              this.$t('workloadVersion.dockerUsernameLength')
            : true,
      };
    },
    tableHeaders() {
      return [
        {
          text: this.$t('workloadVersion.services.name'),
          align: 'start',
          value: 'serviceName',
          fixed: true,
          width: '21%',
          component: {
            sfc: () => import('@/components/workloads/dockerVmComponents/dockerCompose/ComposeServiceName.vue'),
          },
        },
        {
          text: this.$t('workloadVersion.services.imageName'),
          value: 'dockerFilePath',
          filterable: false,
          sortable: false,
          width: '23%',
        },
        {
          text: this.$t('workloadVersion.services.imageFile'),
          value: 'file',
          filterable: false,
          sortable: false,
          width: '23%',
          align: 'bottom',
          component: {
            sfc: () => import('@/components/workloads/dockerVmComponents/dockerCompose/ComposeUploadDockerImage.vue'),
            props: {
              composeFileChanged: this.composeFileChanged,
              isUpdate: this.isUpdate,
            },
          },
        },
        {
          text: this.$t('workloadVersion.services.configurationStorage'),
          value: 'dockerConfigurationStorage',
          filterable: false,
          sortable: false,
          width: '23%',
          component: {
            sfc: () => import('@/components/workloads/dockerVmComponents/dockerCompose/DockerConfigurationStorage.vue'),
          },
        },
        {
          text: this.$t('workloadVersion.services.restartOnConfUpdate'),
          value: 'dockerConfigurationStorage.restartOnConfigurationUpdate',
          filterable: false,
          sortable: false,
          align: 'end',
          width: '10%',
          component: {
            sfc: () =>
              import('@/components/workloads/dockerVmComponents/dockerCompose/RestartOnConfigurationUpdate.vue'),
          },
        },
      ];
    },
    originalFiles() {
      return this.$store.getters['workloads/getOriginalFiles'];
    },
  },
  async mounted() {
    try {
      this.version.registries.forEach((registry) => {
        if (registry && registry.password && registry.password !== '') {
          registry.hidePassword = true;
          registry.textFieldType = 'password';
        }
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    togglePassword(registry) {
      this.version.registries.forEach((reg) => {
        if (reg.registryName === registry.registryName) {
          reg.hidePassword = !reg.hidePassword;
          this.setType(reg);
        }
      });
    },
    setType(registry) {
      registry.textFieldType = registry.hidePassword ? 'password' : 'text';
    },
    passwordChanged(registry) {
      this.version.registries.forEach((reg) => {
        if (reg.registryName === registry.registryName) {
          this.setType(reg);
        }
      });
    },
    getIcon(registry) {
      return registry.hidePassword ? 'mdi-eye-off' : 'mdi-eye';
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
    getRowClass(item) {
      return {
        availableFiles: item.file && item.file.status === 'available',
        failedFiles: item.file && item.file.status === 'failed',
      };
    },
    setNewCredentialsToFile(registry) {
      let services = [];
      if (registry.registryName === 'docker.io') {
        services = this.version.services.filter((service) => !service.dockerFilePath.includes('/'));
      } else if (registry.registryName.includes('docker.io/')) {
        services = this.version.services.filter((service) =>
          service.dockerFilePath.includes(`${registry.registryName.replace('docker.io/', '')}/`),
        );
      } else {
        services = this.version.services.filter((service) =>
          service.dockerFilePath.includes(`${registry.registryName}/`),
        );
      }
      services.forEach((service) => {
        // eslint-disable-next-line max-len
        const oldFile = this.originalFiles.find((originalFile) =>
          shared.compareTwoDockerImageNamesAreEqual(originalFile.file.source, service.dockerFilePath),
        );
        if (this.composeFileChanged || service.file.origin === 'docker-repo') {
          service.file.username = registry.username;
          service.file.password = registry.password;
          this.setChangedFiles({
            newFile: service.file,
            registry,
            oldFile,
            imageName: service.dockerFilePath,
          });
          this.version.services.forEach((s) => {
            if (s.serviceName === service.serviceName) {
              s = service;
            }
          });
        }
      });
    },
    setChangedFiles({ newFile, registry, oldFile, imageName }) {
      if (!oldFile || !oldFile.file || !this.isUpdate || (this.composeFileChanged && this.isUpdate)) {
        return;
      }
      const { file } = oldFile;
      if (isEqual(file, newFile) && newFile.origin === 'docker-repo') {
        this.$store.dispatch('workloads/remove_changed_docker_images', { imageName });
        return;
      }
      // eslint-disable-next-line max-len
      if (file && registry) {
        this.$store.dispatch('workloads/save_changed_docker_images', {
          changedFile: newFile,
          imageName,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.registriesDiv {
  max-height: 65vh !important;
  overflow: auto;
}
.registries-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.registries-scroll::-webkit-scrollbar-thumb {
  @extend .primary-tttech-background-btn;
}
.registries-scroll::-webkit-scrollbar-track {
  background: silver;
}
.registryName {
  font-size: 18px !important;
}
.credentialsTextField input {
  font-size: 16px !important;
}
.servicesTable {
  height: 65vh !important;
}
#iiotNerveSimpleTableRegistriesAndServicesCompose > .v-data-table__wrapper {
  height: 50vh !important;
}
#iiotNerveSimpleTableRegistriesAndServicesCompose > .v-data-table__wrapper > table > tbody > tr {
  height: 66px !important;
}
#iiotNerveSimpleTableRegistriesAndServicesCompose > .v-data-table__wrapper > table > tbody > tr > td {
  max-width: 200px !important;
}
#iiotNerveSimpleTableRegistriesAndServicesCompose > .v-data-table__wrapper > table > thead > tr > th {
  white-space: normal !important;
}
.availableFiles {
  background-color: rgb(196, 230, 196) !important;
}
.failedFiles {
  background-color: rgb(221, 192, 183) !important;
}
.warningMessage {
  color: var(--v-error-base);
}
</style>
